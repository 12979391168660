<template>
  <el-dialog :title="titulo" :visible.sync="visible" :width="width" :before-close="beforeClose">
    <div :key="contentKey">
      <slot></slot>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="default" @click="cerrar" :disabled="impedirClose">Cerrar</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "maca-modal",
  props: {
    titulo: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "900px"
    },
    impedirClose: {
      type: Boolean,
      default: false
    },
    solicitarConfirmacionClose: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      visible: false,
      contentKey: 0
    };
  },
  methods: {
    abrir() {
      this.contentKey += 1;
      this.visible = true;
    },
    cerrar() {
      this.visible = false;
    },
    beforeClose(done) {
      if (this.solicitarConfirmacionClose){
        if (this.impedirClose) {
          return;
        }

        this.$confirm("¿Está seguro de que desea cerrar el formulario?")
            .then(_ => {
              done();
            })
            .catch(() => {});
      }else{
        done()
      }
    }
  }
};
</script>
