/* eslint-disable promise/param-names */
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";

// consultam, alta y baja de items del registro
function obtenerItem(nombre, array = false) {
  let datos = {};

  if (window.localStorage.getItem(process.env.VUE_APP_NAME) != null) {
    datos = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_NAME));
  } else {
    // para roles
    if (array) {
      return [];
    } else {
      return "";
    }
  }

  return datos[nombre];
}
function guardarItem(nombre, data) {
  let datos = {};

  if (window.localStorage.getItem(process.env.VUE_APP_NAME) != null) {
    datos = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_NAME));
  }

  datos[nombre] = data;

  window.localStorage.setItem(process.env.VUE_APP_NAME, JSON.stringify(datos));
}
function borrarItem(nombre) {
  let datos = {};

  if (window.localStorage.getItem(process.env.VUE_APP_NAME) != null) {
    datos = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_NAME));
  } else {
    return;
  }

  datos[nombre] = null;

  window.localStorage.setItem(process.env.VUE_APP_NAME, JSON.stringify(datos));
}
//

const state = {
  token: obtenerItem("token"),
  roles: obtenerItem("roles", true),
  usuario: obtenerItem("usuario"),
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  roles: state => state.roles,
  usuario: state => state.usuario,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      guardarItem("token", user.token);
      guardarItem("roles", user.roles);
      guardarItem("usuario", user.usuario);

      commit(AUTH_SUCCESS, user);
      resolve(user);
    });
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT);
      borrarItem("token");
      borrarItem("roles");
      borrarItem("usuario");
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, user) => {
    state.status = "success";
    state.token = user.token;
    state.roles = user.roles;
    state.usuario = user.usuario;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.roles = "";
    state.usuario = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
