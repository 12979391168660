var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%" },
      style: _vm.appStyle,
      attrs: { id: "app" }
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "transition",
            { attrs: { name: "expand-top-bottom", mode: "out-in" } },
            [
              _c(
                "el-header",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.headerON,
                      expression: "headerON"
                    }
                  ],
                  class: { con_sidebar: _vm.sidebarON },
                  attrs: { id: "app-header-container", height: "50px" }
                },
                [
                  _c("navigation", {
                    on: {
                      "toggle-sidebar": function($event) {
                        return _vm.handleToogleSidebar()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-container",
            { staticStyle: { height: "100%" } },
            [
              _c(
                "transition",
                { attrs: { name: "expand-left-right" } },
                [
                  _c(
                    "el-aside",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.sidebarON,
                          expression: "sidebarON"
                        }
                      ],
                      attrs: { id: "app-aside-container", width: "250px" }
                    },
                    [
                      _c("sidebar", {
                        on: {
                          reporteGanadores: function($event) {
                            return _vm.$refs.modalReporteGanadores.abrir()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("transition", { attrs: { name: "back-fade" } }, [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sidebarON && _vm.$esMovil(),
                      expression: "sidebarON && $esMovil()"
                    }
                  ],
                  attrs: { id: "sidebar-background" },
                  on: {
                    click: function($event) {
                      return _vm.handleToogleSidebar()
                    }
                  }
                })
              ]),
              _c(
                "el-main",
                { class: { con_navbar: _vm.headerON } },
                [
                  _c("transition", { attrs: { name: "expand-top-bottom" } }, [
                    _vm.botonVolverON
                      ? _c(
                          "div",
                          { staticStyle: { "margin-bottom": "10px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  id: "boton-volver",
                                  type: "primary",
                                  icon: "el-icon-arrow-left",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.go(-1)
                                  }
                                }
                              },
                              [_vm._v("Volver")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "transition",
                    { attrs: { name: "fade-quick", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }