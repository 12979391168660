<template>
  <div style="display: flex; width: 100%">
    <el-select
      ref="elSelect"
      v-model="seleccionado"
      remote
      reserve-keyword
      :remote-method="remoteMethod"
      :loading="loading || typeando"
      @change="input($event)"
      :filterable="permitirInput"
      :placeholder="placeholder"
      :clearable="clearable"
      @clear="seleccionarOpcion(null)"
      :size="size"
      :disabled="disabled"
      :multiple="multiple"
    >
      <div slot="prefix" v-if="icon != null">
        <div style="width: 25px; line-height: 40px;">
          <i :class="icon"></i>
        </div>
      </div>
      <!-- Al comienzo, si nosotros le ponemos un valor al select.
      se queda con el ID en la casilla hasta cargar los datos en los select
      normales. En los select de busqueda, al nunca cargar a menos que el usuario
      ponga algo, el ID no se va.

      Para solucionar esto, verificar si el arreglo esta vacio y el value (vmodel)
      no. Si esto ocurre, mostrar una opción en duro con los vamores
      del vmodel. Se utilizarán las opciones reales cuando carguen-->

      <div v-if="opciones.length > 0">
        <el-option
          v-for="item in opciones"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </div>
      <div v-else>
        <el-option
          v-if="value != null"
          :label="value.nombre"
          :value="value.id"
        ></el-option>
      </div>
    </el-select>
    <div class="slot-wrapper">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<style scoped>
.slot-wrapper > * {
  margin-left: 10px;
}
</style>

<script>
export default {
  name: "maca-select-box",
  props: {
    url: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },

    // Opcion elegida por defecto
    default: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object | Array,
      default: () => {},
    },

    getParams: {
      type: Function,
      default: (query) => {},
    },
    itemLabel: {
      type: Array | String,
      default: "nombre",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    necesitaParams: {
      type: Boolean,
      default: false,
    },
    permitirInput: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    seleccionarPrimero: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    soloIniciales: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datosApi: [],
      opciones: [],
      loading: false,
      typeando: false,

      queryTimeout: null,
      ultimoQuery: null,

      seleccionado: null,
    };
  },
  mounted() {
    // solo cargar el componente si necesitamos hacerlo
    // si ya tiene valores, procesar esos.
    if (!this.soloIniciales) {
      // Si necesita params, llamar la funcion del componente padre para obtenerlos.
      // getParams puede necesitar el query del input o no. Ignorar si necesita query
      // Esa verificacion se hace en el get.
      this.recargar();
    } else {
      // Esperar a los demas componentes.
      // Esto es necesario solo en el caso de la secco, por provincias.
      // En secco relevamientos, se cargan las provincias al cargar la vista
      // con un $nextTick. Por lo que el proceso de abajo se debe ejecutar
      // aun más tarde, por eso el 200.
      setTimeout(() => {
        this.datosApi = this.value;
        this.llenarOpciones();
      }, 200);
    }
  },
  methods: {
    recargar(query = null) {
      // no mandar nuevo request si ya hay uno pendiente
      if (this.loading) {
        return;
      }

      if (this.necesitaParams) {
        if (query) {
          this.get(this.getParams(query))
            .then((datos) => this.guardarDatosApi(datos))
            .then(() => this.llenarOpciones());
        } else {
          this.get(this.getParams())
            .then((datos) => this.guardarDatosApi(datos))
            .then(() => this.llenarOpciones());
        }
      } else {
        this.get()
          .then((datos) => this.guardarDatosApi(datos))
          .then(() => this.llenarOpciones());
      }
    },

    guardarDatosApi(datos) {
      // A veces la API trae un objeto en vez de arreglo
      if (Array.isArray(datos) || datos == null) {
        this.datosApi = datos;
      } else {
        this.datosApi = datos.datos;
      }
    },

    // Realizar GET de api. En caso de que el subcomponente necesite params y
    // params sea vacio no realizar consulta
    get(params = "") {
      this.loading = true;

      // verificar parametros, no hacer GET si necesita params y son vacios/nulos
      if (this.necesitaParams) {
        let hayVacios = false;

        if (params == "") {
          hayVacios = true;
        }

        let paramsArray = params.split("&");

        if (paramsArray.length > 1) {
          paramsArray.forEach((paramItem) => {
            let valor = paramItem.split("=").pop();
            if (valor == "" || valor == "undefined" || valor == null) {
              hayVacios = true;
            }
          });
        } else {
          let valor = params.split("=").pop();
          if (valor == "" || valor == "undefined" || valor == null) {
            hayVacios = true;
          }
        }

        if (hayVacios) {
          return new Promise((resolve) => {
            return resolve();
          });
        }
      }

      // TODO probar bien
      // Antes ara if (params != "") para los casos en donde
      // los parametros no eran necesarios y aún así se pasan params
      // No se si hay casos así
      if (this.necesitaParams) {
        return this.$api.get(this.url + "?" + params, this.$usuarioToken());
      } else {
        return this.$api.get(this.url, this.$usuarioToken());
      }
    },

    // Metodo que utiliza el-select para traer la lista de opciones
    // Hacer la consulta a la API, guardar el resultado, y procesar el resultado
    remoteMethod(query) {
      this.ultimoQuery = query;

      // limpiar cada vez que escriba para evitar confuciones en cuanto
      // a qué se esta buscando actualmente
      this.datosApi = [];
      this.llenarOpciones();

      // Mostrar cargando antes de llamar a la API para
      // evitar que se muestre "Sin Datos" al escribir
      this.typeando = true;

      // esperar a que el usuario termine de escribir
      clearTimeout(this.queryTimeout);
      this.queryTimeout = setTimeout(() => {
        this.recargar(query);
        this.typeando = false;
      }, 700);
    },

    // Cuando datosApi tiene datos, formatea los mismos en la variable de opciones
    // para el-select. Formato: [{label:, value:}]
    llenarOpciones() {
      this.loading = false;

      if (this.datosApi == null) {
        return;
      }

      let datos = this.datosApi;
      let opciones = [];

      if (datos != null && !Array.isArray(datos)) {
        datos = datos.datos;
      }

      datos.forEach((itemDatos) => {
        let label = "";
        let value = itemDatos[this.itemValue];

        // Si itemLabel es un arreglo, concatenar atributos en datos para label
        // Ej: :itemLabel="['nombre', 'apellido', 'dni']"
        // Si no, label es igual al valor del atributo
        // Ej: itemLabel='nombre'
        if (Array.isArray(this.itemLabel)) {
          this.itemLabel.forEach((elementLabel) => {
            // Verificar alternativas de label.
            // Ej: para ['nombre', ['dni', 'cuit']], si no hay dni, utilizar cuit
            if (Array.isArray(elementLabel)) {
              let anadido = false;
              elementLabel.forEach((opElementLabel) => {
                if (
                  itemDatos[opElementLabel] != null &&
                  itemDatos[opElementLabel] != "" &&
                  !anadido
                ) {
                  label = label + itemDatos[opElementLabel] + " ";
                  anadido = true;
                }
              });
            } else {
              if (
                itemDatos[elementLabel] != null &&
                itemDatos[elementLabel] != ""
              ) {
                label = label + itemDatos[elementLabel] + " ";
              }
            }
          });
          label = label.substr(0, label.length - 1);
        } else {
          label = itemDatos[this.itemLabel];
        }

        // Si hay un item por defecto, seleccionarlo
        // default={label:, value:}
        if (this.default != null) {
          if (this.default.label == label || this.default.value == value) {
            if (this.multiple) {
              this.agregarAMultiple(value);
            } else {
              this.seleccionado = value;
            }

            let opcion = {};

            if (Array.isArray(this.itemLabel)) {
              opcion[this.itemLabel[0]] = label;
            } else {
              opcion[this.itemLabel] = label;
            }
            opcion[this.itemValue] = value;

            this.seleccionarOpcion(opcion);
          }
        }

        //
        opciones.push({
          label: label,
          value: value,
        });
      });

      // Setear opciones para el-select
      this.opciones = opciones;

      // seleccionar primera opcion si esta seteada la opcion
      if (this.seleccionarPrimero && opciones.length > 0) {
        this.seleccionarOpcion({
          id: opciones[0].value,
          nombre: opciones[0].label,
        });
      }
    },

    // Funcion que se ejecuta al seleccionar una opcion para emitir señales
    // ya sea manualmente, con una funcion externa, o con algun prop de este comp.
    // Para algunos eventos, this.seleccionado ya contiene el valor
    // pero aqui se lo asigna otra vez por las dudas, porque hay veces que esta
    // funcion se llama pero el valor no esta asignado (en el caso del
    // prop "seleccionarPrimero" por ejemplo)
    seleccionarOpcion(opcion) {
      this.$emit("opcion-seleccionada", opcion);

      if (opcion != null) {
        if (this.multiple) {
          this.agregarAMultiple(opcion.id);

          let listaDatos = [];
          this.seleccionado.forEach((elem) => {
            listaDatos.push(this.obtenerDatosSeleccionado(elem));
          });

          this.$emit("input", listaDatos);
          this.$emit("change", listaDatos);
        } else {
          this.seleccionado = opcion.id;
          this.$emit("input", {
            id: opcion.id,
            nombre: opcion.nombre,
          });
          this.$emit("change", {
            id: opcion.id,
            nombre: opcion.nombre,
          });
        }
      } else {
        this.seleccionado = null;
        this.$emit("input", null);
        this.$emit("change", null);
      }
    },

    // Devuelve los datos correspondientes de datosApi a la opcion seleccionada
    obtenerDatosSeleccionado(valueSeleccionado = 0) {
      if (this.datosApi == null) {
        return;
      }

      for (let i = 0; i < this.datosApi.length; i++) {
        if (this.datosApi[i][this.itemValue] === valueSeleccionado) {
          return this.datosApi[i];
        }
      }

      return [];
    },

    // Para procesar evento seleccion de el-select
    // Emite evento "opcion-seleccionada" con datos de datosAPI de la seleccion
    input(valueSeleccionado) {
      this.seleccionarOpcion(this.obtenerDatosSeleccionado(valueSeleccionado));
    },

    // Usado por otros componentes para forzar la seleccion de un elemento
    // si no hay opciones, agregar el dato (objeto) como opcion y seleccionarlo
    // al final recargar para obtener los demás datos si no lo hizo
    seleccionar(dato) {
      if (dato == null) {
        this.seleccionado = null;
        this.seleccionarOpcion(null);
        return;
      }

      if (this.opciones.length == 0) {
        if (Array.isArray(this.itemLabel)) {
          this.opciones.push({
            label: dato[itemLabel[0]],
            value: dato[itemValue],
          });
        } else {
          this.opciones.push({
            label: dato[this.itemLabel],
            value: dato[this.itemLabel],
          });
        }
      }
      if (this.multiple) {
        this.agregarAMultiple(dato.id);
      } else {
        this.seleccionado = dato.id;
      }
      this.seleccionarOpcion(dato);

      this.recargar();
    },

    // Funcion que agrega al arreglo de seleccionados si el select es multiple
    agregarAMultiple(valor) {
      let index = this.seleccionado.findIndex((elemento) => elemento == valor);

      // por alguna razón se agrega undefined al seleccionar algo
      if (index == -1 && valor !== undefined) {
        this.seleccionado.push(valor);
      }
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value != null) {
          if (this.multiple) {
            this.value.forEach((element) => {
              this.agregarAMultiple(element.id);
            });
          } else {
            this.seleccionado = this.value.id;
          }
        } else {
          this.seleccionado = null;
        }
      },
      deep: true,
    },
  },
};
</script>
