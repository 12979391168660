var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-select-box", {
        ref: "selectBox",
        attrs: {
          url: _vm.urlPersona,
          "item-label": ["nombre", "apellido", ["dni"]],
          necesitaParams: true,
          permitirInput: true,
          itemValue: "id",
          placeholder: _vm.placeholder,
          clearable: true,
          getParams: _vm.getParams,
          disabled: _vm.disabled,
          size: _vm.size
        },
        on: {
          "opcion-seleccionada": function(datos) {
            return _vm.$emit("input", datos)
          }
        },
        model: {
          value: _vm.opcionSeleccionada,
          callback: function($$v) {
            _vm.opcionSeleccionada = $$v
          },
          expression: "opcionSeleccionada"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }