import Vue from "vue";

import router from "../router";
import store from "../store";
import roles from "./roles/roles";
import RolesUtils from "./roles/utils";

import common from "./common.js";
import eventStrings from "./event_string.js";
import api from "./api";
import ListJsonManager from "./listJsonManager";

Vue.prototype.$common = common;
Vue.prototype.$eventStrings = eventStrings;
Vue.prototype.$api = api;
Vue.prototype.$listJsonManager = ListJsonManager;
Vue.prototype.$usuarioEs = {
  superadmin: () => RolesUtils.checkRol(roles.SUPERADMINISTRADOR),
  operador: () => RolesUtils.checkRol(roles.OPERADOR),
  supervisor: () => RolesUtils.checkRol(roles.SUPERVISOR)
};
Vue.prototype.$usuarioToken = () => {
  return store.getters.token;
};
Vue.prototype.$usuarioRoles = () => {
  return store.getters.roles;
};
Vue.prototype.$usuario = () => {
  return store.getters.usuario;
};

// Cancelar peticiones cada vez que se cambie de ruta
router.beforeEach((to, from, next) => {
  api.doCancelStack();
  next();
});
//
