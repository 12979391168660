import RolesUtils from "../roles/utils";
import SideMenu from "./sideMenu";
import AC from "./access_control";

let routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    beforeEnter: (to, from, next) => {
      let granted = AC.validateAccess(AC.CONDITIONS.notAuthenticated);
      granted ? next() : next("/");
    }
  },
  {
    path: "/olvidecontrasena",
    name: "OlvideContrasena",
    component: () => import("@/views/olvide_contrasena"),
    beforeEnter: (to, from, next) => {
      let granted = AC.validateAccess(AC.CONDITIONS.notAuthenticated);
      granted ? next() : next("/");
    }
  }
];

// importar las rutas del menu definidas en sideMenu
SideMenu.forEach(item => {
  // comprobar si el item es un item de menu o un submenu
  if (item.children == null) {
    // agregar item del menu
    routes.push({
      path: item.path,
      name: item.name,
      component: item.component,
      beforeEnter: (to, from, next) => {
        let granted = AC.validateAccess(AC.CONDITIONS.authenticated);

        // controlar acceso para los roles
        let rolValido = false;
        item.visibleFor.forEach(rol => {
          if (RolesUtils.checkRol(rol)) {
            rolValido = true;
          }
        });

        if (!rolValido) {
          granted = false;
        }

        granted ? next() : next("/login/");
      }
    });
  } else {
    // el item es un submenu, agregar hijos
    item.children.forEach(child => {
      routes.push({
        path: child.path,
        name: child.name,
        component: child.component,
        beforeEnter: (to, from, next) => {
          let granted = AC.validateAccess(AC.CONDITIONS.authenticated);

          // controlar acceso para los roles
          let rolValido = false;
          child.visibleFor.forEach(rol => {
            if (RolesUtils.checkRol(rol)) {
              rolValido = true;
            }
          });

          if (!rolValido) {
            granted = false;
          }

          granted ? next() : next("/login/");
        }
      });
    });
  }
});

export default routes;
