import store from "../../store";

function checkRol(nombre) {
  let rolesUsuario = [];
  rolesUsuario = store.getters.roles;

  let check = false;

  try {
    let index = rolesUsuario.findIndex(function(element) {
      return element.nombre.toLowerCase() == nombre;
    });

    if (index != -1) {
      check = true;
    }
  } catch (error) {
    return false;
  }

  return check;
}

export default {
  checkRol
};
