var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my_navigation" }, [
    _c("div", { staticStyle: { display: "flex" } }, [
      !_vm.$esMovil()
        ? _c("div", { staticStyle: { "margin-left": "17px" } })
        : _c("div", { staticStyle: { "margin-left": "12px" } }),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-button",
            {
              class: { "boton-menu": true },
              staticStyle: { margin: "auto" },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle-sidebar")
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-menu",
                staticStyle: { "font-size": "23px" }
              })
            ]
          )
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "auto", "margin-right": "0px" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "el-button",
              {
                class: { "boton-menu": true },
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.logout()
                  }
                }
              },
              [_vm._v("Cerrar Sesión")]
            ),
            !_vm.$esMovil()
              ? _c("div", { staticStyle: { "margin-right": "20px" } })
              : _c("div", { staticStyle: { "margin-right": "12px" } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }