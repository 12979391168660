<template>
  <div class="mi-sidebar">
    <div class="sidebar-header">
      <div style="height: 100%; display: flex; justify-content: center;">
        <img
          style="margin: auto; max-width: 200px"
          src="../../../assets/logo-secco-blanco.png"
          alt="Logo Secco"
          height="50px"
        />
      </div>
      <el-popover
        style="margin: auto; margin-left: 0px; margin-bottom: 5px; margin-right: 0px"
        ref="popover"
        placement="right"
        trigger="click"
      >
        <div>
          <span v-if="$usuario()" v-text="$usuario().email"></span>
          <br />
          <span
            v-for="(item, index) in $usuarioRoles()"
            :key="index"
            v-text="item.nombre"
            style="display: block"
          ></span>
        </div>
        <el-button type="text" style="color: white; width: 100%" slot="reference">
          <div style="display: flex">
            <span
              v-if="$usuario()"
              style="margin-left: 20px; text-align: left"
              v-text="$usuario().nombre + ' ' + this.$usuario().apellido"
            ></span>
            <i style="margin: auto; margin-right: 20px" class="el-icon-caret-bottom"></i>
          </div>
        </el-button>
      </el-popover>
    </div>
    <el-menu
      id="sidebar-menu"
      background-color="transparent"
      :default-active="itemMenuActivo"
      :default-openeds="menuItemsAbiertos"
      v-if="$usuarioToken != null"
    >
      <div v-for="(item, index) in menuItems" :key="index">
        <el-menu-item
          v-if="item.children == null && esItemVisible(item)"
          @click="$router.push(item.path)"
          :index="item.path"
        >
          <i :class="item.icon"></i>
          <span v-text="item.name"></span>
        </el-menu-item>
        <el-submenu v-if="item.children != null && esItemVisible(item)" :index="item.path">
          <template slot="title">
            <i :class="item.icon"></i>
            <span v-text="item.name"></span>
          </template>
          <div v-for="(child, j) in item.children" :key="j">
            <el-menu-item
              v-if="esItemVisible(item)"
              @click="$router.push(child.path)"
              :index="child.path"
            >
              <i :class="child.icon"></i>
              <span v-text="child.name"></span>
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </el-menu>
    <div class="sidebar-footer">
      <small class="copyright">
        &copy; {{anioActual}}
        <a href="http://macamedia.com.ar" target="_blank">MACAMEDIA</a>
      </small>
    </div>
  </div>
</template>

<style scoped>
.mi-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-header {
  height: 150px;
  min-height: 150px;
  background-color: #ec2127;
  display: flex;
  flex-direction: column;
}

#sidebar-menu {
  overflow: auto;
}

.el-menu {
  border-right: solid 0px !important;
}

.sidebar-footer {
  border-top: solid 1px #4f5b62;
  height: 50px;
  padding: 10px;
  margin: auto;
  margin-bottom: 0px;
  color: black;
}
</style>

<script>
import { AUTH_LOGOUT } from "../../../store/actions/auth";
import SideMenu from "@/utils/routes/sideMenu";

export default {
  name: "sidebar",
  data() {
    return {
      itemMenuActivo: "home",
      menuItems: [],
      menuItemsAbiertos: [],
      empresa: {},
      anioActual: new Date().getFullYear(),
    };
  },
  computed: {},
  created: function () {
    // traer items del menu
    this.menuItems = SideMenu;

    // Corregir item de menu seleccionado al hacer refresh de pagina
    this.resolverMenuHighlight(this.$router.currentRoute);

    this.$router.afterEach((to) => {
      this.resolverMenuHighlight(to);
    });
  },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
      this.$router.replace("/login");
    },

    // Cuando se recarga la pagina, colocar como activo el item correcto de la ruta
    resolverMenuHighlight(to) {
      this.menuItems.forEach((item) => {
        if (item.children == null) {
          if (to.name == item.name) {
            this.itemMenuActivo = item.path;
          }
        } else {
          item.children.forEach((child) => {
            if (to.name == child.name) {
              this.itemMenuActivo = child.path;
            }
          });
        }
      });
    },

    esItemVisible(item) {
      let visible = false;

      // Verificar que el usuario este logueado para procesar los roles
      if (this.$usuarioRoles() == null) {
        return false;
      }

      try {
        this.$usuarioRoles().forEach((rolUsuario) => {
          if (item.visibleFor.indexOf(rolUsuario.nombre.toLowerCase()) !== -1) {
            visible = true;
          }
        });
      } catch (error) {
        console.log(this.$usuarioRoles());
      }

      return visible;
    },
  },
};
</script>
