<template>
  <div class="my_navigation">
    <div style="display: flex">
      <!-- Si es de escritorio, poner margen grande, si no poner margen chico -->
      <div v-if="!$esMovil()" style="margin-left: 17px;"></div>
      <div v-else style="margin-left: 12px;"></div>
      <div style="display: flex;">
        <el-button
          :class="{ 'boton-menu': true }"
          style="margin:auto"
          type="text"
          @click="$emit('toggle-sidebar')"
        >
          <i class="el-icon-menu" style="font-size: 23px"></i>
        </el-button>
      </div>

      <div style="margin:auto; margin-right: 0px">
        <div style="display: flex">
          <el-button :class="{ 'boton-menu': true }" type="text" @click="logout()">Cerrar Sesión</el-button>
          <!-- Si es de escritorio, poner margen grande, si no poner margen chico -->
          <div v-if="!$esMovil()" style="margin-right: 20px;"></div>
          <div v-else style="margin-right: 12px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu-mas-boton {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.menu-mas-boton + .menu-mas-boton {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: 0px;
  border-top: 1px solid #ececec;
}

.my_navigation {
  height: 100%;
  padding: 0px;
}
.nombre-app {
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
  width: 220px;
}

.contenedor-titulo {
  background-color: #37474f;
  color: #fbfbfb;
  font-size: 18px;
  margin-left: 50px;
  margin-top: -10px;
  height: 50px;
  width: 300px;
  padding: 10px;
  display: flex;
}

@media only screen and (max-device-width: 768px) {
  .nombre-app > button > span > img {
    width: 100px;
    height: auto !important;
    max-height: 20px !important;
  }
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "../../../store/actions/auth";

export default {
  name: "navigation",
  data() {
    return {};
  },
  computed: {},
  components: {},
  mounted() {},
  methods: {
    logout() {
      this.$confirm("¿Desea cerrar la sesión?")
        .then(_ => {
          this.notificaciones = {};
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
          //this.$router.replace("/login");
        })
        .catch(_ => {});
    }
  }
};
</script>
