// Se usa AXIOS para los request
const axios = require("axios");

import { AUTH_LOGOUT } from "../../store/actions/auth";
import router from "../../router";
import store from "../../store";

// Importar objetos handler. Son los que procesarán la respuesta de la API
import HandlerRespuesta from "./handler_respuesta_api.js";
import HandlerError from "./handler_error_api.js";
//

// Importar Vue para mostrar alertas
import Vue from "vue";

// Obtener URL de API
const URL = process.env.VUE_APP_API_URL;

// Tiempo de timeout para pedidos GET y POST
const defaultGetTimeout = 20000;
const defaultPostTimeout = 30000;
//

// Max de intentos antes de rendirse en un GET
const maxIntentos = 10;

// Array de peticiones a cancelar una vez que se abandona la vista.
let cancelStack = [];

//////////////////////////////////////////////////////////////////////

function toLoginPage() {
  store.dispatch(AUTH_LOGOUT).then(() => router.replace("/login"));
}

// ***
// Cancelar todas las peticiones en cancelStack
// ***
function doCancelStack() {
  cancelStack.forEach(cancelSource => {
    cancelSource.cancel(
      "Requests cancelado por cambio de página. " + cancelSource.name
    );
  });
}

// ***
// Sacar una consulta de cancelStack. Se llama cuando la petición terminó
// ***
function removeFromCancelStack(name) {
  for (let index = 0; index < cancelStack.length; index++) {
    const cancelSource = cancelStack[index];

    if (cancelSource.name == name) {
      cancelStack.splice(index, 1);
    }
  }
}

// ***
// Función pública para descargar un archivo
// ***
function downloadFile(url, token = "", nombre = "file.txt") {
  const httpClient = axios.create();
  httpClient.defaults.headers = {
    Authorization: "Bearer " + token
  };

  return httpClient
    .get(url, {
      responseType: "blob" // important
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre);
      document.body.appendChild(link);
      link.click();

      return true;
    });
}

// ***
// Función pública para hacer una petición GET
// ***
function get(
  url,
  token = "",
  timeout = defaultGetTimeout,
  mensajeError = true,
  cancelOnRouteChange = true,
  intentoNro = 1
) {
  // Crear un cliente AXIOS para hacer la consulta y
  //  obtener un token para poder cancelarla
  const httpClient = axios.create();
  let params = {};
  let cancelTokenSource = null;

  // Si la petición se puede cancelar, obtener token de cancelación
  if (cancelOnRouteChange) {
    const cancelToken = axios.CancelToken;
    cancelTokenSource = cancelToken.source();

    cancelTokenSource.name = url;

    cancelStack.push(cancelTokenSource);
    params.cancelToken = cancelTokenSource.token;
  }
  //

  // Timeout desactivado con 0.
  // Se desactiva cuando hay que subir archivos.
  if (timeout != 0) {
    httpClient.defaults.timeout = timeout;
  }

  // Si se proporsionó token, agregar header a petición
  if (token != "" && token != null) {
    httpClient.defaults.headers = { Authorization: "Bearer " + token };
  }

  // Ejecutar petición y devolver promesa con datos
  return httpClient
    .get(url, params)
    .then(function(response) {
      // Petición finalizada con éxito

      // Obtener handler. Proporsionando la respuesta
      let handler = new HandlerRespuesta(response, params);

      // Si la petición es cancelable quiere decir que está en la lista para ser
      // cancelada al cambiar de vista. La petición terminó, así que sacarla
      // de la lista
      if (cancelTokenSource != null) {
        removeFromCancelStack(cancelTokenSource.name);
      }
      //

      // Comprobar si hubo errores de API. Llevar a login si hay error de Token.
      // Si hay errores, retorna promesa con datos vacios.
      if (handler.hayErrores()) {
        // Error de token, llevar a login
        if (
          handler.estado == -100 ||
          handler.estado == -110 ||
          handler.estado == -120
        ) {
          toLoginPage();
        } else {
          if (mensajeError) {
            let vue = new Vue();
            vue.$mostrarMensajeError(
              "Error inesperado al cargar los datos.",
              handler
            );
          }
        }

        // si es un error desconocido, no devolver nada
        return null;
      }
      //

      // Retornar promesa con el atributo datos recibido de API
      return handler.datos;
    })
    .catch(function(error) {
      // Hubo un error al realizar la petición

      // Obtener handler. Proporsionando la respuesta
      let handler = new HandlerError(error);

      // Si la petición es cancelable quiere decir que está en la lista para ser
      // cancelada al cambiar de vista. La petición terminó, así que sacarla
      // de la lista
      if (cancelTokenSource != null) {
        removeFromCancelStack(cancelTokenSource.name);
      }

      // Si el error es 1, es timeout o error 500, volver a realizar la consulta
      if (handler.error == 1) {
        if (intentoNro < maxIntentos) {
          return get(
            url,
            token,
            httpClient.defaults.timeout + 2000,
            cancelOnRouteChange,
            mensajeError,
            intentoNro + 1
          );
        }
      }

      // Si es un error desconocido, no devolver nada
      return null;
    });
}

// ***
// Función pública para hacer una petición POST
// ***
function post(url, params, token = "", timeout = defaultPostTimeout) {
  // Crear un cliente AXIOS para hacer la consulta
  const httpClient = axios.create();

  // Timeout desactivado con 0.
  // Se desactiva cuando hay que subir archivos.
  if (timeout != 0) {
    httpClient.defaults.timeout = timeout;
  }

  // Si se proporsionó token, agregar header a petición
  if (token != "" && token != null) {
    httpClient.defaults.headers = { Authorization: "Bearer " + token };
  }
  //

  // Realizar petición y devolver promesa con datos
  return httpClient
    .post(url, params)
    .then(function(response) {
      // Petición finalizada con éxito

      // Obtener handler. Proporsionando la respuesta
      let handler = new HandlerRespuesta(response, params);

      return handler;
    })
    .catch(function(error) {
      // Hubo un error al realizar la petición

      // Obtener handler. Proporsionando la respuesta
      let handler = new HandlerError(error);

      // Retorna promesa con falso
      return handler;
    });
}

export default {
  doCancelStack,
  get,
  post,
  URL,
  defaultGetTimeout,
  defaultPostTimeout,
  cancelStack,
  downloadFile
};
