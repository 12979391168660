import roles from "../roles/roles";

let menuItems = [
  {
    path: "/",
    name: "Inicio",
    icon: "el-icon-s-home",
    visibleFor: [roles.SUPERADMINISTRADOR, roles.OPERADOR, roles.SUPERVISOR],
    component: () => import("@/views/home"),
  },
  {
    path: "/empleados",
    name: "Empleados",
    icon: "el-icon-s-custom",
    visibleFor: [roles.SUPERADMINISTRADOR],
    component: () => import("@/views/empleados"),
  },
  {
    path: "/productos",
    name: "Productos",
    icon: "el-icon-s-goods",
    visibleFor: [roles.SUPERADMINISTRADOR],
    component: () => import("@/views/productos"),
  },
  {
    path: "/puntos-venta",
    name: "Puntos de Venta",
    icon: "el-icon-s-flag",
    visibleFor: [roles.SUPERADMINISTRADOR, roles.OPERADOR, roles.SUPERVISOR],
    component: () => import("@/views/puntos_venta"),
  },
  {
    path: "/relevamientos",
    name: "Relevamientos",
    icon: "el-icon-s-claim",
    visibleFor: [roles.SUPERADMINISTRADOR, roles.OPERADOR, roles.SUPERVISOR],
    component: () => import("@/views/relevamientos"),
  },
  {
    path: "/distribuidores",
    name: "Distribuidores",
    icon: "el-icon-truck",
    visibleFor: [roles.SUPERADMINISTRADOR, roles.OPERADOR, roles.SUPERVISOR],
    component: () => import("@/views/distribuidores"),
  },
  {
    path: "/ajustes", // no se usa como ruta, solo para el menu
    name: "Ajustes",
    icon: "el-icon-s-tools",
    visibleFor: [roles.SUPERADMINISTRADOR],
    children: [
      {
        path: "/ajustes/zonas",
        name: "Zonas",
        icon: "el-icon-s-flag",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/zonas"),
      },
      {
        path: "/ajustes/tipos_calibre",
        name: "Tipos de Calibre",
        icon: "el-icon-s-operation",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/tipos_calibre"),
      },
      {
        path: "/ajustes/tipos_comprador_secco",
        name: "Tipos de Comprador",
        icon: "el-icon-house",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/tipos_comprador_secco"),
      },
      {
        path: "/ajustes/tipos_canal",
        name: "Tipos de Canal",
        icon: "el-icon-folder-opened",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/tipos_canal"),
      },
      {
        path: "/ajustes/marcas",
        name: "Marcas",
        icon: "el-icon-collection-tag",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/marcas"),
      },
      {
        path: "/ajustes/tipos_producto",
        name: "Tipos de Producto",
        icon: "el-icon-box",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/tipos_producto"),
      },
      {
        path: "/ajustes/usuarios",
        name: "Usuarios",
        icon: "el-icon-user",
        visibleFor: [roles.SUPERADMINISTRADOR],
        component: () => import("@/views/ajustes/usuarios"),
      },
    ],
  },
];

export default menuItems;
