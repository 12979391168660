var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mi-sidebar" },
    [
      _c(
        "div",
        { staticClass: "sidebar-header" },
        [
          _vm._m(0),
          _c(
            "el-popover",
            {
              ref: "popover",
              staticStyle: {
                margin: "auto",
                "margin-left": "0px",
                "margin-bottom": "5px",
                "margin-right": "0px"
              },
              attrs: { placement: "right", trigger: "click" }
            },
            [
              _c(
                "div",
                [
                  _vm.$usuario()
                    ? _c("span", {
                        domProps: { textContent: _vm._s(_vm.$usuario().email) }
                      })
                    : _vm._e(),
                  _c("br"),
                  _vm._l(_vm.$usuarioRoles(), function(item, index) {
                    return _c("span", {
                      key: index,
                      staticStyle: { display: "block" },
                      domProps: { textContent: _vm._s(item.nombre) }
                    })
                  })
                ],
                2
              ),
              _c(
                "el-button",
                {
                  staticStyle: { color: "white", width: "100%" },
                  attrs: { slot: "reference", type: "text" },
                  slot: "reference"
                },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _vm.$usuario()
                      ? _c("span", {
                          staticStyle: {
                            "margin-left": "20px",
                            "text-align": "left"
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$usuario().nombre +
                                " " +
                                this.$usuario().apellido
                            )
                          }
                        })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-caret-bottom",
                      staticStyle: { margin: "auto", "margin-right": "20px" }
                    })
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$usuarioToken != null
        ? _c(
            "el-menu",
            {
              attrs: {
                id: "sidebar-menu",
                "background-color": "transparent",
                "default-active": _vm.itemMenuActivo,
                "default-openeds": _vm.menuItemsAbiertos
              }
            },
            _vm._l(_vm.menuItems, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  item.children == null && _vm.esItemVisible(item)
                    ? _c(
                        "el-menu-item",
                        {
                          attrs: { index: item.path },
                          on: {
                            click: function($event) {
                              return _vm.$router.push(item.path)
                            }
                          }
                        },
                        [
                          _c("i", { class: item.icon }),
                          _c("span", {
                            domProps: { textContent: _vm._s(item.name) }
                          })
                        ]
                      )
                    : _vm._e(),
                  item.children != null && _vm.esItemVisible(item)
                    ? _c(
                        "el-submenu",
                        { attrs: { index: item.path } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { class: item.icon }),
                            _c("span", {
                              domProps: { textContent: _vm._s(item.name) }
                            })
                          ]),
                          _vm._l(item.children, function(child, j) {
                            return _c(
                              "div",
                              { key: j },
                              [
                                _vm.esItemVisible(item)
                                  ? _c(
                                      "el-menu-item",
                                      {
                                        attrs: { index: child.path },
                                        on: {
                                          click: function($event) {
                                            return _vm.$router.push(child.path)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", { class: child.icon }),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(child.name)
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "sidebar-footer" }, [
        _c("small", { staticClass: "copyright" }, [
          _vm._v("\n      © " + _vm._s(_vm.anioActual) + "\n      "),
          _c(
            "a",
            { attrs: { href: "http://macamedia.com.ar", target: "_blank" } },
            [_vm._v("MACAMEDIA")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          height: "100%",
          display: "flex",
          "justify-content": "center"
        }
      },
      [
        _c("img", {
          staticStyle: { margin: "auto", "max-width": "200px" },
          attrs: {
            src: require("../../../assets/logo-secco-blanco.png"),
            alt: "Logo Secco",
            height: "50px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }