import Vue from "vue";

import MacaSelectBox from "./form/select_box";
import MacaSelectBoxCliente from "./form/select_box/cliente.vue";
import MacaSelectBoxEmpleado from "./form/select_box/empleado.vue";
import MacaSelectBoxPersona from "./form/select_box/persona.vue";
import MacaInputFecha from "./form/fecha";
import MacaInputRangoFecha from "./form/rango_fecha";
import MacaInputFechaHora from "./form/fecha_hora";
import MacaUbicacion from "./form/ubicacion";
import MacaBotonGuardar from "./form/boton_guardar";
import MacaDatatable from "./datatable";
import MacaDatatableFiltrar from "./datatable/filtrar.vue";
import MacaDatatableFiltrarCustom from "./datatable/filtrar_custom.vue";
import MacaDatatableFiltrarRangoFecha from "./datatable/filtrar_rangofecha.vue";
import MacaDatatableFiltrarBuscar from "./datatable/filtrar_buscar.vue";
import MacaDatatableFiltrarSelect from "./datatable/filtrar_select.vue";
import MacaDatatableFiltrarSelectPersona from "./datatable/filtrar_select_persona.vue";
import MacaMensajeError from "./mensaje/error";
import MacaModal from "./modal";
import MostrarMensajeError from "./mensaje/error/mostrar_mensaje";

Vue.component("maca-select-box", MacaSelectBox);
Vue.component("maca-select-box-cliente", MacaSelectBoxCliente);
Vue.component("maca-select-box-empleado", MacaSelectBoxEmpleado);
Vue.component("maca-select-box-persona", MacaSelectBoxPersona);
Vue.component("maca-input-fecha", MacaInputFecha);
Vue.component("maca-input-rango-fecha", MacaInputRangoFecha);
Vue.component("maca-input-fechahora", MacaInputFechaHora);
Vue.component("maca-ubicacion", MacaUbicacion);
Vue.component("maca-boton-guardar", MacaBotonGuardar);
Vue.component("maca-datatable", MacaDatatable);
Vue.component("maca-datatable-filtrar", MacaDatatableFiltrar);
Vue.component("maca-datatable-filtrar-custom", MacaDatatableFiltrarCustom);
Vue.component(
  "maca-datatable-filtrar-rangofecha",
  MacaDatatableFiltrarRangoFecha
);
Vue.component("maca-datatable-filtrar-buscar", MacaDatatableFiltrarBuscar);
Vue.component("maca-datatable-filtrar-select", MacaDatatableFiltrarSelect);
Vue.component(
  "maca-datatable-filtrar-select-persona",
  MacaDatatableFiltrarSelectPersona
);
Vue.component("maca-mensaje-error", MacaMensajeError);
Vue.component("maca-modal", MacaModal);

Vue.prototype.$mostrarMensajeError = MostrarMensajeError;
