import Vue from "vue";
import App from "./components/App";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import PortalVue from "portal-vue";
import { sync } from "vuex-router-sync";

//
Vue.use(PortalVue);

// Vue Cli plugins
import "./plugins/element.js";

// Componentes de macamedia
import "./components/macamedia";

// Utils de macamedia
import "./utils";

// Para los input de telefonos
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// Moment
const moment = require("moment");
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale("es");

// Leaflet para mapas
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LPopup,
  LIcon,
  LTooltip,
} from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);
Vue.component("l-control", LControl);
Vue.component("l-popup", LPopup);
Vue.component("l-tooltip", LTooltip);
Vue.component("l-marker-cluster", Vue2LeafletMarkercluster);
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Otros
Vue.prototype.$esMovil = () => {
  return window.outerWidth <= 768;
};

Vue.config.productionTip = false;
sync(store, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export { App, router, store };
